import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { PrivacyNotice } from './privacy-notice-loader'

interface Props {
  privacyNotice: PrivacyNotice
}

const PrivacyNoticePage: React.FC<Props> = ({ privacyNotice }) => {
  return (
    <div
      css={css`
        background-color: #eee;
        min-height: 100vh;
        width: 100%;
      `}
    >
      <HeaderContainer>
        <HeaderTitle>
          Informativa Privacy {privacyNotice.subject} N. {privacyNotice.id}
        </HeaderTitle>
      </HeaderContainer>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 32px;
        `}
      >
        <MarkdownContainer>
          <ReactMarkdown children={privacyNotice.body} remarkPlugins={[remarkGfm]} />
        </MarkdownContainer>
      </div>
      <div
        css={css`
          height: 80px;
          margin-top: 24px;
          background-color: #f4b627;
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            color: #fff;
            margin-left: auto;
            margin-right: 18px;
          `}
        >
          Egg S.r.l.&trade; 2020
        </span>
      </div>
    </div>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #f4b628;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const HeaderTitle = styled.h1`
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 576px) {
    font-size: 24px;
  }
`

const MarkdownContainer = styled.div`
  background-color: #fff;
  padding: 48px;
  font-size: 14px;
  max-width: 960px;
  min-height: 80vh;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0px 0px 18px #ccc;
  line-height: 1.5;

  @media (max-width: 576px) {
    padding: 24px;
  }

  h1 {
    font-size: 22px;
    margin-bottom: 24px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  b,
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  ul,
  ol {
    list-style: none;
  }

  li {
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  table,
  th,
  td {
    border: 1px solid;
  }

  table {
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%;

    thead {
      background-color: #eee;
    }

    th,
    td {
      padding: 4px;
    }

    th {
      font-weight: bold;
      vertical-align: middle;
      text-align: left;
    }
  }
`

export default PrivacyNoticePage
