import React from 'react'
import { Global, css } from '@emotion/core'
import emotionReset from 'emotion-reset'
import { Switch, Route } from 'react-router-dom'
import Home from './components/home'
import PrivacyNoticeLoader from './components/privacy-notice-loader'

function App() {
  return (
    <div>
      <Global
        styles={css`
          ${emotionReset}

          @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

          body {
            font-family: 'Roboto';
          }
        `}
      />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/:privacyCode">
          <PrivacyNoticeLoader />
        </Route>
      </Switch>
    </div>
  )
}

export default App
