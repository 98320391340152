import React from 'react'
import mediaQueries from '../styles/media-queries'

interface Props {
  errorMessage?: 'notFound' | 'malformedRequest' | 'genericError'
}

function ErrorPage({ errorMessage }: Props) {
  let message: string = ''

  switch (errorMessage) {
    case 'genericError':
      message = 'Errore generico'
      break
    case 'notFound':
      message = 'Codice privacy non trovato'
      break

    case 'malformedRequest':
      message = 'Errore del server'
      break
  }

  return (
    <div
      css={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f4b627',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 16px',
        }}
      >
        <span
          css={{
            color: '#fff',
            fontSize: '4em',
            fontWeight: 300,
            marginBottom: 16,
            [mediaQueries('sm', 'max')]: {
              fontSize: '3em',
            },
          }}
        >
          Errore
        </span>
        <span
          css={{
            color: '#fff',
            fontSize: '3em',
            fontWeight: 300,
            textAlign: 'center',
            marginBottom: 16,
            [mediaQueries('sm', 'max')]: {
              fontSize: '2em',
            },
          }}
        >
          {message}
        </span>
        <span
          css={{
            color: '#fff',
            fontSize: '1.5em',
            fontWeight: 300,
            textAlign: 'center',
            [mediaQueries('sm', 'max')]: {
              fontSize: '0.9em',
            },
          }}
        >
          Per accedere alla tua informativa scrivi a{' '}
          <a
            href="mailto:info@egg.srl"
            css={{
              color: '#fff',
              '&:visited': {
                color: '#fff',
              },
            }}
          >
            info@egg.srl
          </a>
        </span>
      </div>
    </div>
  )
}

export default ErrorPage
