import React from 'react'
import { useParams } from 'react-router-dom'
import { findPrivacyNotice } from '../apis'
import PrivacyNoticePage from './privacy-notice-page'
import Loading from './loading'
import ErrorPage from './error-page'

export interface PrivacyNotice {
  id: string
  body: string
  subject: string
}

interface State {
  privacyNotice?: PrivacyNotice
  loading: boolean
  error?: 'notFound' | 'malformedRequest' | 'genericError'
}

type Action =
  | {
      type: 'privacyNoticeLoaded'
      privacyNotice: PrivacyNotice
    }
  | {
      type: 'error'
      errorType: 'notFound' | 'malformedRequest' | 'genericError'
    }

const PrivacyNoticeLoader: React.FC<{}> = () => {
  const [state, dispatch] = React.useReducer(reducer, { loading: true })
  const { privacyCode } = useParams()

  React.useEffect(() => {
    findPrivacyNotice(privacyCode)
      .then((response) => {
        if (response.status === 200 && response.data.privacyNotice) {
          dispatch({ type: 'privacyNoticeLoaded', privacyNotice: response.data.privacyNotice })
        }
      })
      .catch((reason) => {
        if (reason.response.status === 404) {
          dispatch({ type: 'error', errorType: 'notFound' })
        } else if (reason.response.status === 400) {
          dispatch({ type: 'error', errorType: 'malformedRequest' })
        } else {
          dispatch({ type: 'error', errorType: 'genericError' })
        }
      })
  }, [privacyCode])

  if (state.loading) {
    return <Loading />
  } else if (state.error || !state.privacyNotice) {
    return <ErrorPage errorMessage={state.error} />
  } else {
    return <PrivacyNoticePage privacyNotice={state.privacyNotice} />
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'privacyNoticeLoaded':
      return { ...state, privacyNotice: action.privacyNotice, loading: false }
    case 'error':
      return { ...state, loading: false, error: action.errorType }
  }
}

export default PrivacyNoticeLoader
