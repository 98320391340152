import React from 'react'
import logo from '../images/logo-egg.svg'
import styled from '@emotion/styled'
import CodeForm from './code-form'
import mediaQueries from '../styles/media-queries'

const Home: React.FC<{}> = () => {
  return (
    <div
      css={{
        backgroundColor: '#F4B628',
        color: '#fff',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        padding: 16,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        [mediaQueries('md')]: {
          padding: 32,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          [mediaQueries('xl')]: {
            flexDirection: 'row',
            maxWidth: 1400,
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            padding: 32,
            [mediaQueries('xl')]: {
              width: '40%',
            },
          }}
        >
          <div
            css={{
              flexGrow: 1,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div css={{ textAlign: 'center' }}>
              <img alt="Logo" src={logo} css={{ width: 250 }} />
            </div>
            <div>
              <h1
                css={{
                  textAlign: 'center',
                  fontSize: 44,
                  fontWeight: 300,
                  textTransform: 'uppercase',
                  marginTop: 32,
                  [mediaQueries('sm', 'max')]: {
                    fontSize: 32,
                  },
                }}
              >
                Informative Privacy per Medici e Pazienti
              </h1>
            </div>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            padding: 32,
            [mediaQueries('xl')]: { width: '60%' },
            [mediaQueries('sm', 'max')]: {
              padding: 16,
            },
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <h3
              css={{
                fontSize: 32,
                lineHeight: 1.2,
                fontWeight: 300,
                marginBottom: 32,
                textAlign: 'center',
                [mediaQueries('sm', 'max')]: {
                  fontSize: 26,
                },
              }}
            >
              Sei un Medico od un Paziente che vuole aderire o ha aderito ad un Programma di Supporto al Paziente di Egg
              e vuoi prendere visione dell'informativa privacy?
            </h3>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 32,
                flexDirection: 'column',
                [mediaQueries('md')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                },
              }}
            >
              <Section
                css={{
                  [mediaQueries('md')]: {
                    paddingRight: 32,
                    borderRight: '1px solid rgba(255, 255, 255, .5)',
                  },
                }}
              >
                <SectionTitle>Accedi alla tua informativa tramite l'url diretto</SectionTitle>
                <SectionText>
                  Nel modulo di adesione o all'interno dei materiali informativi troverai l'indirizzo della pagina che
                  contiene la tua informativa. Inserisci l'indirizzo nel tuo browser per leggere la tua informativa
                  privacy.
                </SectionText>
              </Section>
              <Section css={{ marginTop: 32, [mediaQueries('md')]: { paddingLeft: 32, marginTop: 0 } }}>
                <SectionTitle>Accedi alla tua informativa con il numero identificativo</SectionTitle>
                <SectionText>
                  Nel modulo di adesione o all'interno dei materiali informativi hai trovato il numero identificativo
                  della tua informativa privacy? Inserisci il numero nel campo sottostante per accedere alla tua
                  informativa privacy.
                </SectionText>
                <CodeForm css={{ marginTop: 32 }} />
              </Section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Section = styled.div({
  [mediaQueries('md')]: {
    width: '50%',
  },
})

const SectionTitle = styled.h2({
  fontSize: 24,
  marginBottom: 16,
  lineHeight: 1.4,
})

const SectionText = styled.p({
  fontSize: 20,
  lineHeight: 1.4,
  fontWeight: 300,
  textAlign: 'justify',
  textJustify: 'inter-word',
})

export default Home
