import axios from 'axios'

const findPrivacyNoticeUrl = process.env.REACT_APP_FIND_PRIVACY_NOTICE_URL

export function findPrivacyNotice(privacyCode: string) {
  if (findPrivacyNoticeUrl) {
    return axios.post(findPrivacyNoticeUrl, { privacyCode })
  } else {
    throw new Error('REACT_APP_FIND_PRIVACY_NOTICE_URL should be defined in env')
  }
}
