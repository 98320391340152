import React from 'react'
import mediaQueries from '../styles/media-queries'

const Loading: React.FC<{}> = () => {
  return (
    <div
      css={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f4b627',
      }}
    >
      <span
        css={{
          color: '#fff',
          fontSize: '5em',
          fontWeight: 300,
          [mediaQueries('sm', 'max')]: {
            fontSize: '3em',
          },
        }}
      >
        Loading...
      </span>
    </div>
  )
}

export default Loading
