import React, { ChangeEvent, FormEvent } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  className?: string
}

const CodeForm: React.FC<Props> = ({ className }) => {
  const [code, setCode] = React.useState<string>('')
  const history = useHistory()

  const onChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value)
  }, [])

  const onSubmit = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      history.push(`/${code}`)
    },
    [code, history]
  )

  return (
    <div className={className}>
      <form onSubmit={onSubmit} css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <label css={{ marginRight: 8, fontSize: 18, flexGrow: 1 }}>Codice</label>
        <input
          type="text"
          value={code}
          onChange={onChange}
          css={{
            border: 'none',
            borderRadius: 5,
            height: 28,
            fontSize: 14,
            padding: 4,
            flexGrow: 2,
            color: '#333',
            width: '100%',
            '&:focus': {
              outline: 'none',
            },
          }}
        />
        <button
          css={{
            fontSize: 18,
            borderRadius: 5,
            marginLeft: 8,
            border: 'none',
            padding: '8px 12px',
            backgroundColor: '#DFA521',
            color: '#fff',
            cursor: 'pointer',
            '&:focus': {
              outline: 'none',
            },
            '&:hover': {
              backgroundColor: '#C68F10',
            },
          }}
        >
          Invia
        </button>
      </form>
    </div>
  )
}

export default CodeForm
