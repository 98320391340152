const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

type Breakpoints = 'sm' | 'md' | 'lg' | 'xl'
type BreakpointType = 'min' | 'max'

export default function (bp: Breakpoints, type: BreakpointType = 'min') {
  return `@media (${type}-width: ${breakpoints[bp]}px)`
}
